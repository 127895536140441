import React, { useEffect, useState } from 'react';
import '../ComponetCSS/ListMovies.css';
import { useNavigate } from 'react-router-dom';
import removeAccents from 'remove-accents';

const ListMovies = ({ verificar }) => {
    const [tv, setTV] = useState([]);
    const [search, setSearch] = useState([]);
    const [size, setSize] = useState([]);
    const [quantMovie, setQuantMovie] = useState(0);
    const [message, setMessage] = useState('');

    const [formData, setFormData] = useState({
        nome: '',
        data: '',
        categoria: '',
        tempo: '',
        approval: '',
        sinopse: '',
        img: '',
        temporadas: [
            {
                nomeTemporada: '', numero: '',
                episodios: [{ nomeEpisodio: '', link: '' }],
            },
        ],
    });

    const [isUpdatingAll, setIsUpdatingAll] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        // caso for diferente de true, volta ao login
        if (verificar !== true) {
            navigate('/');
        } else {
            navigate('/listTV');
        }
    }, [navigate, verificar]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch('https://netmoviesapi.vercel.app/Api/services/tv');
                const data = await res.json();
                const filterData = data.reverse();
                setQuantMovie(filterData.length);
                setTV(filterData);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [message]);

    const handleDelete = (id) => {
        const confirmDelete = window.confirm('Tem certeza de que deseja excluir esta serie?');

        if (confirmDelete) {
            fetch(`https://netmoviesapi.vercel.app/Api/delete/${id}`, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (response.ok) {
                        setMessage('Item deletado com sucesso');
                    } else {
                        setMessage('Erro ao deletar item');
                    }
                })
                .catch((error) => {
                    setMessage('Erro na requisição: ' + error);
                });
        }
    };

    const handleUpdate = (id, field, value, isUpdatingAll) => {
        if (isUpdatingAll) {
            setIsUpdatingAll(true);

            const data = {
                nome: formData.nome,
                data: formData.data,
                categoria: formData.categoria,
                tempo: formData.tempo,
                approval: formData.approval,
                sinopse: formData.sinopse,
                img: formData.img,
                temporadas: formData.temporadas.map((temporada) => ({
                    nomeTemporada: temporada.nomeTemporada,
                    episodios: temporada.episodios.map((episodio) => ({
                        nomeEpisodio: episodio.nomeEpisodio,
                        link: episodio.link,
                    })),
                })),
            };

            fetch(`https://netmoviesapi.vercel.app/Api/update/tv/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Erro ao atualizar a serie');
                    }
                })
                .then((data) => {
                    setMessage('Serie atualizado com sucesso: ' + data.value);
                    setIsUpdatingAll(false);
                })
                .catch((error) => {
                    setMessage('Erro ao atualizar a serie: ' + error);
                    // Lide com o erro adequadamente
                    setIsUpdatingAll(false);
                });
        } else {
            const data = {
                [field]: value,
            };

            fetch(`https://netmoviesapi.vercel.app/Api/update/tv/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Erro ao atualizar a Serie');
                    }
                })
                .then((data) => {
                    setMessage(data.msg);
                })
                .catch((error) => {
                    setMessage('Erro ao atualizar a Serie: ' + error);
                });
        }
    };

    //Buscar serie edit
    const handleSearch = (e) => {
        const movieSearch = e.target.value.toLowerCase();

        //remove acentos,espaços e colocar em ordem do que ta sendo digitado
        const check = movieSearch ? tv.filter(movies => {
            const termoPesquisaSemAcento = removeAccents(movieSearch.toLowerCase().replace(/\s+/g, '.*\\b').trim());
            const nomeFilmeSemAcento = removeAccents(movies.nome.toLowerCase());
            const regex = new RegExp(`\\b${termoPesquisaSemAcento}.*`, 'i');
            return regex.test(nomeFilmeSemAcento);
        }) : [];

        setSize(check.length)
        setSearch(check);
    }


    //Resetar inputs
    const handleReset = () => {
        setMessage(false);
        setFormData({
            nome: '',
            data: '',
            categoria: '',
            tempo: '',
            approval: '',
            sinopse: '',
            img: '',
            temporadas: [
                {
                    nomeTemporada: '',
                    episodios: [{ nomeEpisodio: '', link: '' }],
                },
            ],
        });
    };

    //Valores do input
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleTemporadaChange = (e, temporadaIndex) => {
        const { value } = e.target;
        console.log(value);
        const updatedTemporadas = formData.temporadas.map((temporada, index) => {
            if (index === temporadaIndex) {
                return { ...temporada, nome: value };
            }
            return temporada;
        });

        setFormData((prevState) => ({
            ...prevState,
            temporadas: updatedTemporadas
        }));
    };

    const handleEpisodioChange = (e, temporadaIndex, episodioIndex) => {
        const { name, value } = e.target;
        const [campo, campoTipo, temporadaId, episodioId] = name.split("-");

        if (campoTipo === "nome") {
            // Atualizar o nome do episódio
            const updatedTemporadas = formData.temporadas.map((temporada, tIndex) => {
                if (tIndex === parseInt(temporadaId)) {
                    const updatedEpisodios = temporada.episodios.map((episodio, eIndex) => {
                        if (eIndex === parseInt(episodioId)) {
                            return { ...episodio, nome: value };
                        }
                        return episodio;
                    });
                    return { ...temporada, episodios: updatedEpisodios };
                }
                return temporada;
            });

            setFormData((prevState) => ({
                ...prevState,
                temporadas: updatedTemporadas
            }));
        } else if (campoTipo === "link") {
            // Atualizar o link do episódio
            const updatedTemporadas = formData.temporadas.map((temporada, tIndex) => {
                if (tIndex === parseInt(temporadaId)) {
                    const updatedEpisodios = temporada.episodios.map((episodio, eIndex) => {
                        if (eIndex === parseInt(episodioId)) {
                            return { ...episodio, link: value };
                        }
                        return episodio;
                    });
                    return { ...temporada, episodios: updatedEpisodios };
                }
                return temporada;
            });

            setFormData((prevState) => ({
                ...prevState,
                temporadas: updatedTemporadas
            }));
        }
    };

    const handleAddTemporada = () => {
        setFormData((prevState) => ({
            ...prevState,
            temporadas: [...prevState.temporadas, { nome: '', numero: '', episodios: [] }]
        }));
    };



    const handleAddEpisodio = (temporadaIndex) => {
        setFormData((prevFormData) => {
            const newEpisodio = { nomeEpisodio: '', link: '' };

            const newTemporadas = [...prevFormData.temporadas];
            newTemporadas[temporadaIndex] = {
                ...newTemporadas[temporadaIndex],
                episodios: [...newTemporadas[temporadaIndex].episodios, newEpisodio],
            };
            return {
                ...prevFormData, temporadas: newTemporadas,
            };
        });
    };

    return (
        <div className="container_list">
            <div className="menu">
                <div>
                    <h1>Total: {quantMovie} / Pesquisa: {size}</h1>
                </div>
                <div>
                    <input type="text" placeholder="Digite o nome da serie" onChange={(e) => handleSearch(e)} />
                </div>
            </div>
            {search ? (
                search.map((movie) => {
                    return (
                        <div key={movie._id}>
                            <p>{movie.nome}</p>
                            <button onClick={() => handleReset()}>Reset</button>
                            <button id="deleteButton" onClick={() => handleDelete(movie._id)}>
                                Deletar
                            </button>
                            <button onClick={() => handleUpdate(movie._id, '', '', true)}>Atualizar Todos</button>
                            <p>{message}</p>
                            <div className="container_child">
                                <label>
                                    <input
                                        type="text"
                                        name="nome"
                                        value={formData.nome}
                                        onChange={handleChange}
                                        placeholder={movie.nome}
                                    />
                                    <button onClick={() => handleUpdate(movie._id, 'nome', formData.nome)}>
                                        Atualizar Nome
                                    </button>
                                </label>
                                <label>
                                    <input
                                        type="number"
                                        name="data"
                                        value={formData.data}
                                        onChange={handleChange}
                                        placeholder={movie.data}
                                    />
                                    <button onClick={() => handleUpdate(movie._id, 'data', formData.data)}>
                                        Atualizar Data
                                    </button>
                                </label>
                                <label>
                                    <input
                                        type="text"
                                        name="categoria"
                                        value={formData.categoria}
                                        onChange={handleChange}
                                        placeholder={movie.categoria}
                                    />
                                    <button onClick={() => handleUpdate(movie._id, 'categoria', formData.categoria)}>
                                        Atualizar Categoria
                                    </button>
                                </label>
                                <label>
                                    <input
                                        type="text"
                                        name="tempo"
                                        value={formData.tempo}
                                        onChange={handleChange}
                                        placeholder={movie.tempo}
                                    />
                                    <button onClick={() => handleUpdate(movie._id, 'tempo', formData.tempo)}>
                                        Atualizar Temporada
                                    </button>
                                </label>
                                <label>
                                    <input
                                        type="text"
                                        name="approval"
                                        value={formData.approval}
                                        onChange={handleChange}
                                        placeholder={movie.approval}
                                    />
                                    <button onClick={() => handleUpdate(movie._id, 'approval', formData.approval)}>
                                        Atualizar Aprovação
                                    </button>
                                </label>
                                <label>
                                    <input
                                        type="text"
                                        name="sinopse"
                                        value={formData.sinopse}
                                        onChange={handleChange}
                                        placeholder={movie.sinopse}
                                    />
                                    <button onClick={() => handleUpdate(movie._id, 'sinopse', formData.sinopse)}>
                                        Atualizar sinopse
                                    </button>
                                </label>
                                <label>
                                    <input
                                        type="text"
                                        name="img"
                                        value={formData.img}
                                        onChange={handleChange}
                                        placeholder={movie.img}
                                    />
                                    <button onClick={() => handleUpdate(movie._id, 'img', formData.img)}>
                                        Atualizar img
                                    </button>
                                </label>
                                {formData.temporadas.map((temporada, temporadaIndex) => (
                                    <div key={temporadaIndex}>
                                        <input
                                            type="text"
                                            name={`temporada-${temporadaIndex}`}
                                            placeholder="Nome da temporada"
                                            value={temporada.nome}
                                            onChange={(e) => handleTemporadaChange(e, temporadaIndex)}
                                            autoComplete="off"
                                            required
                                        />
                                        {/* Campos para os episódios */}
                                        {/* Campos para os episódios */}
                                        {temporada.episodios.map((episodio, episodioIndex) => (
                                            <div key={episodioIndex}>
                                                <input
                                                    type="text"
                                                    name={`episodio-nome-${temporadaIndex}-${episodioIndex}`}
                                                    placeholder={`Episódio ${episodioIndex + 1} - Nome`}
                                                    value={episodio.nome}
                                                    onChange={(e) => handleEpisodioChange(e, temporadaIndex, episodioIndex)}
                                                    autoComplete="off"
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    name={`episodio-link-${temporadaIndex}-${episodioIndex}`}
                                                    placeholder={`Episódio ${episodioIndex + 1} - Link`}
                                                    value={episodio.link}
                                                    onChange={(e) => handleEpisodioChange(e, temporadaIndex, episodioIndex)} // Novo handler específico para o link
                                                    autoComplete="off"
                                                    required
                                                />
                                                <button type="button" onClick={() => handleUpdate(movie._id, 'temporadas', formData.temporadas)}>
                                                    Editar Episodio
                                                </button>
                                            </div>
                                        ))}
                                        <button type="button" onClick={() => handleAddEpisodio(temporadaIndex)}>
                                            Adicionar Episódio
                                        </button>
                                        <button type="button" onClick={() => handleUpdate(movie._id, 'temporadas', formData.temporadas)}>

                                            Enviar Temporada
                                        </button>

                                        <button onClick={handleReset}>
                                            Reset
                                        </button>
                                    </div>
                                ))}

                                <button type="button" onClick={handleAddTemporada}>
                                    Adicionar Temporada
                                </button>
                            </div>
                        </div>
                    );
                })
            ) : (
                []
            )}

        </div>
    );
};

export default ListMovies;