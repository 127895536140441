import React from 'react'
import '../ComponetCSS/Login.css'
import logo from '../img/logotipo.png'


const PainelLogin = ({ message, user, handleSubmit, setuser }) => {


  return (
    <div className='Login'>
      <div>

        <form className='form'>
          <div className='field'>
            <label htmlFor='Usuario'>Usuario</label>
            <input type='text' name="usuario" id="usuario" placeholder='Usuario' required onChange={(e) => setuser({ ...user, name: e.target.value })}></input>
          </div>
          <div className='field'>
            <label htmlFor='Usuario'>Senha</label>
            <input type='password' name="senha" id="senha" placeholder='Senha' required onChange={(e) => setuser({ ...user, password: e.target.value })}></input>
          </div>
          <button onClick={(e) => handleSubmit(e)}>Login</button>
          {message ? <p>{message}</p> : <p></p>}

        </form>
        {/* <img src={logo} alt="logo" /> */}
      </div>
    </div>
  )
}

export default PainelLogin