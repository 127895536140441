import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ComponetCSS/Painel.css'


const PainelMovie = ({ verificar }) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // verificar login true or false
    var check = verificar;

    useEffect(() => {
        // caso for diferente de true, volta ao login
        if (check !== true) {
            navigate('/');
        } else {
            navigate('/painelTv');
        }
    }, []);

    const [formData, setFormData] = useState({
        nome: '',
        data: '',
        categoria: '',
        tempo: '',
        approval: '',
        sinopse: '',
        img: '',
        temporadas: [
            {
                nomeTemporada: '', numero: '',
                episodios: [
                    { nomeEpisodio: '', link: '' }
                ]
            }
        ]
    });


    const handleTemporadaChange = (e, temporadaIndex) => {
        const { value } = e.target;
        console.log(value);
        const updatedTemporadas = formData.temporadas.map((temporada, index) => {
            if (index === temporadaIndex) {
                return { ...temporada, nome: value };
            }
            return temporada;
        });

        setFormData((prevState) => ({
            ...prevState,
            temporadas: updatedTemporadas
        }));
    };

    const handleEpisodioChange = (e, temporadaIndex, episodioIndex) => {
        const { name, value } = e.target;
        const [campo, campoTipo, temporadaId, episodioId] = name.split("-");

        if (campoTipo === "nome") {
            // Atualizar o nome do episódio
            const updatedTemporadas = formData.temporadas.map((temporada, tIndex) => {
                if (tIndex === parseInt(temporadaId)) {
                    const updatedEpisodios = temporada.episodios.map((episodio, eIndex) => {
                        if (eIndex === parseInt(episodioId)) {
                            return { ...episodio, nome: value };
                        }
                        return episodio;
                    });
                    return { ...temporada, episodios: updatedEpisodios };
                }
                return temporada;
            });

            setFormData((prevState) => ({
                ...prevState,
                temporadas: updatedTemporadas
            }));
        } else if (campoTipo === "link") {
            // Atualizar o link do episódio
            const updatedTemporadas = formData.temporadas.map((temporada, tIndex) => {
                if (tIndex === parseInt(temporadaId)) {
                    const updatedEpisodios = temporada.episodios.map((episodio, eIndex) => {
                        if (eIndex === parseInt(episodioId)) {
                            return { ...episodio, link: value };
                        }
                        return episodio;
                    });
                    return { ...temporada, episodios: updatedEpisodios };
                }
                return temporada;
            });

            setFormData((prevState) => ({
                ...prevState,
                temporadas: updatedTemporadas
            }));
        }
    };

    const handleAddTemporada = () => {
        setFormData((prevState) => ({
            ...prevState,
            temporadas: [...prevState.temporadas, { nome: '', numero: '', episodios: [] }]
        }));
    };

    const handleAddEpisodio = (temporadaIndex) => {

        setFormData((prevState) => {
            const temporadas = [...prevState.temporadas];
            temporadas[temporadaIndex].episodios.push({ nome: '', link: '' });
            return { ...prevState, temporadas };
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isSubmitting) {
            setIsSubmitting(true);
            setMessage('Enviando...');
            try {
                const response = await fetch('https://netmoviesapi.vercel.app/Api/services/tv', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                const json = await response.json();
                setMessage(json.msg)
                setIsSubmitting(false);

            } catch (error) {
                console.error(error);
                setMessage("Erro ao adicionar a série");
            }
        }
    };

    const handleReset = () => {
        setMessage(false);
        setFormData({
            nome: '',
            data: '',
            categoria: '',
            tempo: '',
            approval: '',
            sinopse: '',
            img: '',
            temporadas: [],
        });
    };

    const handleExit = () => {
        localStorage.removeItem("Check");
        navigate("/");
    }

    return (
        <div>
            <div className='logout'>
                <p> <a href="/painel">Adicionar Filmes</a> </p>
                <p> <a href="/painelTv">Adicionar Séries</a> </p>
                <p> <a href="/painelCanal">Adicionar Canal</a> </p>
                <p> <a href="/list">Editar Filmes</a> </p>
                <p> <a href="/listTV">Editar Séries</a> </p>
                <p> <a href="/search">Buscar Filmes</a> </p>
                <button onClick={handleExit}>Sair</button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='container_input'>
                    <input
                        type="text"
                        name="nome"
                        placeholder='Titulo'
                        value={formData.nome}
                        onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    <input
                        type="text"
                        name="data"
                        placeholder='Lançamento'
                        value={formData.data}
                        onChange={(e) => setFormData({ ...formData, data: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    <input
                        type="text"
                        name="categoria"
                        placeholder='Categoria'
                        value={formData.categoria}
                        onChange={(e) => setFormData({ ...formData, categoria: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    <input
                        type="text"
                        name="tempo"
                        placeholder='QTD de Temporadas'
                        value={formData.tempo}
                        onChange={(e) => setFormData({ ...formData, tempo: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    <input
                        type="text"
                        name="approval"
                        placeholder='Aprovação'
                        value={formData.approval}
                        onChange={(e) => setFormData({ ...formData, approval: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    <input
                        type="text"
                        name="sinopse"
                        placeholder='Sinopse'
                        value={formData.sinopse}
                        onChange={(e) => setFormData({ ...formData, sinopse: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    <input
                        type="text"
                        name="img"
                        placeholder='IMG'
                        value={formData.img}
                        onChange={(e) => setFormData({ ...formData, img: e.target.value })}
                        autoComplete="off"
                        required
                    />

                    {formData.temporadas.map((temporada, temporadaIndex) => (
                        <div key={temporadaIndex}>
                            <input
                                type="text"
                                name={`temporada-${temporadaIndex}`}
                                placeholder="Nome da temporada"
                                value={temporada.nome}
                                onChange={(e) => handleTemporadaChange(e, temporadaIndex)}
                                autoComplete="off"
                                required
                            />

                            {/* Campos para os episódios */}
                            {temporada.episodios.map((episodio, episodioIndex) => (
                                <div key={episodioIndex}>
                                    <input
                                        type="text"
                                        name={`episodio-nome-${temporadaIndex}-${episodioIndex}`}
                                        placeholder={`Episódio ${episodioIndex + 1} - Nome`}
                                        value={episodio.nome}
                                        onChange={(e) => handleEpisodioChange(e, temporadaIndex, episodioIndex)}
                                        autoComplete="off"
                                        required
                                    />

                                    <input
                                        type="text"
                                        name={`episodio-link-${temporadaIndex}-${episodioIndex}`}
                                        placeholder={`Episódio ${episodioIndex + 1} - Link`}
                                        value={episodio.link}
                                        onChange={(e) => handleEpisodioChange(e, temporadaIndex, episodioIndex)} // Novo handler específico para o link
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                            ))}
                            <button type="button" onClick={() => handleAddEpisodio(temporadaIndex)}>Adicionar Episódio</button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddTemporada}>Adicionar Temporada</button>

                    <div className='bt'>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Aguarde...' : 'Enviar'}
                        </button>
                        <button onClick={handleReset}>Reset</button>
                        {message ? <p className='message'>{message}</p> : null}

                    </div>
                </div>
            </form>
        </div>
    )
}


export default PainelMovie;
