import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import removeAccents from 'remove-accents';
import '../ComponetCSS/Search.css'


const SearchMovies = ({ verificar }) => {

    const [allMovie, setAllMovie] = useState();
    const [movies, setMovies] = useState([]);
    const [size, setSize] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        // caso for diferente de true, volta ao login
        if (verificar !== true) {
            navigate('/');
        } else {
            navigate('/search');
        }
    }, [navigate, verificar]);


    useEffect(() => {
        async function fetchData() {
            try {
                const [resMDB, resFLY] = await Promise.all([
                    fetch('https://netmoviesapi.vercel.app/Api/services'),
                    fetch('https://netmoviesw.fly.dev/categorias')
                ]);

                const [dataMDB, dataFLY] = await Promise.all([
                    resMDB.json(),
                    resFLY.json(),
                ]);

                setAllMovie([...dataMDB, ...dataFLY.all]);
        
            } catch (error) {
                console.log("Ocorreu um erro, tente novamente mais tarde");
            }
        }
        fetchData();
    }, [])


    //Buscar filme
    const handleSearch = (e) => {
        const movieSearch = e.target.value.toLowerCase();

        //remove acentos,espaços e colocar em ordem do que ta sendo digitado
        const check = movieSearch ? allMovie.filter(movies => {
            const termoPesquisaSemAcento = removeAccents(movieSearch.toLowerCase().replace(/\s+/g, '.*\\b').trim());
            const nomeFilmeSemAcento = removeAccents(movies.nome.toLowerCase());
            const regex = new RegExp(`\\b${termoPesquisaSemAcento}.*`, 'i');
            return regex.test(nomeFilmeSemAcento);
        }) : [];

        setSize(check.length)
        setMovies(check);
    }

    const handleExit = () => {
        localStorage.removeItem("Check");
        navigate("/");
    }


    return (
        <div className='container_movieSearch'>
            <div className="container_search">
                <div className='logout'>
                    <p> <a href="/painel">Adicionar Filmes</a> </p>
                    <p> <a href="/painelTv">Adicionar Séries</a> </p>
                    <p> <a href="/painelCanal">Adicionar Canal</a> </p>
                    <p> <a href="/list">Editar Filmes</a> </p>
                    <p> <a href="/listTV">Editar Séries</a> </p>
                    <p> <a href="/search">Buscar Filmes</a> </p>
                    <button onClick={handleExit}>Sair</button>
                </div>
                <div className='searchInput'>
                    <div>
                        <h1>Pesquisa: {size}</h1>
                        <input type="text" placeholder='Nome Do Filme' onChange={(e) => handleSearch(e)} />
                    </div>
                </div>
            </div>

            {movies.map((movie) => (
                <div className='movies_search'>
                    <p>{movie.nome}</p>
                </div>
            ))}
        </div>
    )
}

export default SearchMovies