import './App.css'
import PainelMovie from './Components/PainelMovie';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import PainelLogin from './Components/PainelLogin';
import PainelTvShows from './Components/PainelTvShows';
import Menu from './Components/Menu';
import { useState } from 'react';
import ListMovies from './Components/ListMovies';
import ListTvShows from './Components/ListTvShows';
import SearchMovies from './Components/SearchMovies';
import PainelCanal from './Components/PainelCanal';



function App() {


  const data = localStorage.getItem("Check");

  //guarda o valor da req de login
  const [verificar, setverificar] = useState(data ? JSON.parse(data) : []);
  const [message, setMessage] = useState();

  const [user, setuser] = useState({
    name: '',
    password: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_LOGIN_API, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify(user),
      },

      );

      const json = await response.json();


      if (json == true) {
        window.location.replace(process.env.REACT_APP_PAINEL);
        localStorage.setItem("Check", JSON.stringify(json));
        setverificar(json);
      } else {
        //msg de error para o user
        setMessage(json.msg);
        localStorage.setItem("Check", JSON.stringify(verificar))
        setverificar(json)
      }

    } catch (error) {
      console.log('cath', error)
    }
  };


  return (
    <div>
      <Menu />
      <BrowserRouter Comics serRouter>
        <Routes>
          {/* check if verificar is true */}
          {verificar ? <Route exact path='/painel' element={<PainelMovie verificar={verificar} />} /> : <Route exact path='/' element={<PainelLogin />} />}
          {verificar ? <Route exact path='/list' element={<ListMovies verificar={verificar} />} /> : <Route exact path='/' element={<PainelLogin />} />}
          {verificar ? <Route exact path='/painelTv' element={<PainelTvShows verificar={verificar} />} /> : <Route exact path='/' element={<PainelLogin />} />}
          {verificar ? <Route exact path='/listTV' element={<ListTvShows verificar={verificar} />} /> : <Route exact path='/' element={<PainelLogin />} />}
          {verificar ? <Route exact path='/search' element={<SearchMovies verificar={verificar} />} /> : <Route exact path='/' element={<PainelLogin />} />}
          {verificar ? <Route exact path='/painelCanal' element={<PainelCanal verificar={verificar} />} /> : <Route exact path='/' element={<PainelLogin />} />}
          <Route exact path='/' element={<PainelLogin message={message} handleSubmit={handleSubmit} user={user} setuser={setuser} />} />
          <Route exact path='*' element={<PainelLogin />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;