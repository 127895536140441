import React, { useEffect, useState } from 'react';
import '../ComponetCSS/ListMovies.css';
import { useNavigate } from 'react-router-dom';
import removeAccents from 'remove-accents';

const ListMovies = ({ verificar }) => {
    const [movies, setMovies] = useState([]);
    const [search, setSearch] = useState([]);
    const [size, setSize] = useState([]);
    const [quantMovie, setQuantMovie] = useState(0);
    const [message, setMessage] = useState('');

    const [formData, setFormData] = useState({
        nome: '',
        data: '',
        categoria: '',
        categoriaEsp: '',
        tempo: '',
        approval: '',
        sinopse: '',
        img: '',
        link: '',
    });

    const [isUpdatingAll, setIsUpdatingAll] = useState(false);
    const navigate = useNavigate();

    // verificar login true or false
    var check = verificar;

    useEffect(() => {
        // caso for diferente de true, volta ao login
        if (check !== true) {
            navigate('/');
        } else {
            navigate('/list');
        }
    }, []);


    //GetMovies
    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch(process.env.REACT_APP_API_MOVIE);
                const data = await res.json();
                const filterData = data.reverse();
                setQuantMovie(filterData.length);
                setMovies(filterData);
                console.log(filterData);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [message]);

    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Tem certeza de que deseja excluir este filme?");

        if (confirmDelete) {
            fetch(`https://netmoviesapi.vercel.app/Api/delete/${id}`, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (response.ok) {
                        setMessage('Item deletado com sucesso');
                    } else {
                        setMessage('Erro ao deletar item');
                    }
                })
                .catch((error) => {
                    setMessage('Erro na requisição: ' + error);
                });
        }
    };


    const handleUpdate = (id, field, value, isUpdatingAll) => {
        if (isUpdatingAll) {
            setIsUpdatingAll(true);

            const data = {
                nome: formData.nome,
                data: formData.data,
                categoria: formData.categoria,
                categoriaEsp: formData.categoriaEsp,
                tempo: formData.tempo,
                approval: formData.approval,
                sinopse: formData.sinopse,
                img: formData.img,
                link: formData.link,
            };

            fetch(`https://netmoviesapi.vercel.app/Api/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Erro ao atualizar o filme');
                    }
                })
                .then((data) => {
                    setMessage('Filme atualizado com sucesso: ' + data.value);
                    setIsUpdatingAll(false);
                })
                .catch((error) => {
                    setMessage('Erro ao atualizar o filme: ' + error);
                    // Lide com o erro adequadamente
                    setIsUpdatingAll(false);
                });
        } else {
            const data = {
                [field]: value,
            };

            fetch(`https://netmoviesapi.vercel.app/Api/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Erro ao atualizar o filme');
                    }
                })
                .then((data) => {
                    setMessage('Filme atualizado com sucesso: ' + data.value);
                })
                .catch((error) => {
                    setMessage('Erro ao atualizar o filme: ' + error);
                    // Lide com o erro adequadamente
                });
        }
    };

    //Buscar filme edit
    const handleSearch = (e) => {
        const movieSearch = e.target.value.toLowerCase();

        //remove acentos,espaços e colocar em ordem do que ta sendo digitado
        const check = movieSearch ? movies.filter(movies => {
            const termoPesquisaSemAcento = removeAccents(movieSearch.toLowerCase().replace(/\s+/g, '.*\\b').trim());
            const nomeFilmeSemAcento = removeAccents(movies.nome.toLowerCase());
            const regex = new RegExp(`\\b${termoPesquisaSemAcento}.*`, 'i');
            return regex.test(nomeFilmeSemAcento);
        }) : [];

        setSize(check.length)
        setSearch(check);
    }

    //Resetar inputs
    const handleReset = () => {
        setMessage(false);
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setFormData({
            itemvalues: [{}]
        });
    };

    //Valores do input
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="container_list">
            <div className='menu'>

                <div>
                    <h1>Total: {quantMovie} / Pesquisa: {size}</h1>
                </div>
                <div>
                    <input type="text" placeholder='Digite o nome do filme' onChange={(e) => handleSearch(e)} />
                </div>
            </div>
            {search ? search.map((movie) => {
                return (
                    <div key={movie._id}>
                        <p>{movie.nome}</p>
                        <button onClick={() => handleReset()}>Reset</button>
                        <button id='deleteButton' onClick={() => handleDelete(movie._id)}>Deletar</button>
                        <button onClick={() => handleUpdate(movie._id, '', '', true)}>Atualizar Todos</button>
                        <p>{message}</p>
                        <div className="container_child">
                            <label>
                                <input
                                    type="text"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}
                                    placeholder={movie.nome}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'nome', formData.nome)}>
                                    Atualizar Nome
                                </button>
                            </label>
                            <label>
                                <input
                                    type="number"
                                    name="data"
                                    value={formData.data}
                                    onChange={handleChange}
                                    placeholder={movie.data}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'data', formData.data)}>
                                    Atualizar Data
                                </button>
                            </label>
                            <label>
                                <input
                                    type="text"
                                    name="categoria"
                                    value={formData.categoria}
                                    onChange={handleChange}
                                    placeholder={movie.categoria}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'categoria', formData.categoria)}>
                                    Atualizar Categoria
                                </button>
                            </label>
                            <label>
                                <input
                                    type="text"
                                    name="categoriaEsp"
                                    value={formData.categoriaEsp}
                                    onChange={handleChange}
                                    placeholder={movie.categoriaEsp}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'categoriaEsp', formData.categoriaEsp)}>
                                    Atualizar Categoria Esp
                                </button>
                            </label>
                            <label>
                                <input
                                    type="text"
                                    name="tempo"
                                    value={formData.tempo}
                                    onChange={handleChange}
                                    placeholder={movie.tempo}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'tempo', formData.tempo)}>
                                    Atualizar Duração
                                </button>
                            </label>
                            <label>

                                <input
                                    type="text"
                                    name="approval"
                                    value={formData.approval}
                                    onChange={handleChange}
                                    placeholder={movie.approval}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'approval', formData.approval)}>
                                    Atualizar Aprovação
                                </button>
                            </label>
                            <label>
                                <input
                                    type="text"
                                    name="sinopse"
                                    value={formData.sinopse}
                                    onChange={handleChange}
                                    placeholder={movie.sinopse}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'sinopse', formData.sinopse)}>
                                    Atualizar sinopse
                                </button>
                            </label>
                            <label>
                                <input
                                    type="text"
                                    name="img"
                                    value={formData.img}
                                    onChange={handleChange}
                                    placeholder={movie.img}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'img', formData.img)}>
                                    Atualizar img
                                </button>
                            </label>
                            <label>
                                <input
                                    type="text"
                                    name="link"
                                    value={formData.link}
                                    onChange={handleChange}
                                    placeholder={movie.link}
                                />
                                <button onClick={() => handleUpdate(movie._id, 'link', formData.link)}>Atualizar link</button>
                            </label>
                        </div>
                    </div>
                );
            }) : []}
        </div>
    );
};

export default ListMovies;
