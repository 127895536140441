import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ComponetCSS/Painel.css'

const PainelTvShows = ({ verificar }) => {
    const navigate = useNavigate();


    // verificar login true or false
    var check = verificar;


    useEffect(() => {
        // caso for diferente de true, volta ao login
        if (check !== true) {
            navigate('/');
        } else {
            navigate('/painelCanal');
        }
    }, []);

    const [formData, setFormData] = useState({
        nome: '',
        img: '',
        categoria: '',
        temporadas: [
            {
                nomeOpcao: '',
                opcoesLink: [
                    { link: '' }
                ]
            }
        ]
    });

    const [message, setMessage] = useState(false);

    const HandleOption = (e, temporadaIndex) => {
        const { value } = e.target;
        console.log(value);
        const updatedTemporadas = formData.temporadas.map((temporada, index) => {
            if (index === temporadaIndex) {
                return { ...temporada, nomeOpcao: value };
            }
            return temporada;
        });

        setFormData((prevState) => ({
            ...prevState,
            temporadas: updatedTemporadas
        }));
    };

    const handleEpisodioChange = (e, temporadaIndex, episodioIndex) => {
        const { name, value } = e.target;
        const [campo, campoTipo, temporadaId, episodioId] = name.split("-");
        console.log(episodioId);

        if (campoTipo === "link") {
            // Atualizar o link do episódio
            const updatedTemporadas = formData.temporadas.map((temporada, tIndex) => {
                if (tIndex === parseInt(temporadaId)) {
                    const updatedEpisodios = temporada.opcoesLink.map((episodio, eIndex) => {
                        if (eIndex === parseInt(episodioId)) {
                            return { ...episodio, link: value };
                        }
                        return episodio;
                    });
                    return { ...temporada, opcoesLink: updatedEpisodios };
                }
                return temporada;
            });

            setFormData((prevState) => ({
                ...prevState,
                temporadas: updatedTemporadas
            }));
        }
    };

    const handleAddTemporada = () => {
        setFormData((prevState) => ({
            ...prevState,
            temporadas: [...prevState.temporadas, { nomeOpcao: '', opcoesLink: [] }]
        }));
    };

    const handleAddEpisodio = (temporadaIndex) => {

        setFormData((prevState) => {
            const temporadas = [...prevState.temporadas];

            temporadas[temporadaIndex].opcoesLink.push({ link: '' });
            return { ...prevState, temporadas };
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://netmoviesapi.vercel.app/Api/services/canal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const json = await response.json();
            console.log(json);
            setMessage("Canal Adicionado");
        } catch (error) {
            console.error(error);
            setMessage("Erro ao adicionar o Canal");
        }
    };

    const handleReset = () => {
        setMessage(false);
        setFormData({
            nome: '',
            categoria: '',
            img: '',
            temporadas: [],
        });
    };

    const handleExit = () => {
        localStorage.removeItem("Check");
        navigate("/");
    }

    return (
        <div>
            <div className='logout'>
                <p> <a href="/painel">Adicionar Filmes</a> </p>
                <p> <a href="/painelTv">Adicionar Séries</a> </p>
                <p> <a href="/painelCanal">Adicionar Canal</a> </p>
                <p> <a href="/list">Editar Filmes</a> </p>
                <p> <a href="/listTV">Editar Séries</a> </p>
                <p> <a href="/search">Buscar Filmes</a> </p>
                <button onClick={handleExit}>Sair</button>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='container_input'>
                    <input
                        type="text"
                        name="nome"
                        placeholder='Titulo'
                        value={formData.nome}
                        onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    <input
                        type="text"
                        name="img"
                        placeholder='IMG'
                        value={formData.img}
                        onChange={(e) => setFormData({ ...formData, img: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    <input
                        type="text"
                        name="categoria"
                        placeholder='Categoria'
                        value={formData.categoria}
                        onChange={(e) => setFormData({ ...formData, categoria: e.target.value })}
                        autoComplete="off"
                        required
                    />
                    {formData.temporadas.map((temporada, temporadaIndex) => (
                        <div key={temporadaIndex}>
                            <select
                                name={`temporada-${temporadaIndex}`}
                                value={temporada.nomeOpcao}
                                onChange={(e) => HandleOption(e, temporadaIndex)}
                                autoComplete="off"
                                required
                            >
                                <option value="">Selecione uma opção</option>
                                <option value="opcao1">Opção 1 - 1080p</option>
                                <option value="opcao2">Opção 2 - 480p</option>
                                <option value="opcao3">Alternativo</option>
                            </select>

                            {/* Campos para os episódios */}
                            {temporada.opcoesLink.map((episodio, episodioIndex) => (
                                <div key={episodioIndex}>
                                    <input
                                        type="text"
                                        name={`episodio-link-${temporadaIndex}-${episodioIndex}`}
                                        placeholder={`Link Opção - ${episodioIndex + 1}`}
                                        value={episodio.link}
                                        onChange={(e) => handleEpisodioChange(e, temporadaIndex, episodioIndex)} // Novo handler específico para o link
                                        autoComplete="off"

                                    />
                                </div>
                            ))}
                            <button type="button" onClick={() => handleAddEpisodio(temporadaIndex)}>Adicionar Variação</button>
                        </div>
                    ))}

                    <button type="button" onClick={handleAddTemporada}>Adicionar + Opção</button>

                    <div className='bt'>
                        <button type="submit">Enviar</button>
                        <button onClick={handleReset}>Reset</button>
                        {message ? <p className='message'>{message}</p> : null}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PainelTvShows;
