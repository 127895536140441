import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ComponetCSS/Painel.css'
import removeAccents from 'remove-accents';

var count = -1;

const PainelMovie = ({ verificar }) => {

  const navigate = useNavigate();

  //verificar login true or false
  var check = verificar;


  useEffect(() => {
    //caso for != true volta ao login
    if (check != true) {
      navigate("/");
    } else {
      navigate("/painel");
    }

  }, [])

  const [formData, setFormData] = useState({
    nome: '',
    data: '',
    categoria: '',
    categoriaEsp: '',
    tempo: '',
    approval: '',
    sinopse: '',
    img: '',
    link: '',
  });
  const [message, setmessage] = useState(false);
  const [allMovie, setAllMovie] = useState();
  const [movies, setMovies] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const [resMDB, resFLY] = await Promise.all([
          fetch('https://netmoviesapi.vercel.app/Api/services'),
          fetch('https://netmoviesw.fly.dev/categorias')
        ]);

        const [dataMDB, dataFLY] = await Promise.all([
          resMDB.json(),
          resFLY.json(),
        ]);

        console.log(dataMDB);
        setAllMovie([...dataMDB.reverse(), ...dataFLY.all]);
      } catch (error) {
        setmessage("Ocorreu um erro, tente novamente mais tarde");
      }
    }
    fetchData();

    handleSearch();
  }, [])


  //Check movie exists
  const handleSearch = (e) => {
    const movieSearch = formData.nome.toLowerCase();

    //remove acentos,espaços e colocar em ordem do que ta sendo digitado
    const check = movieSearch ? allMovie.filter(movies => {
      const termoPesquisaSemAcento = removeAccents(movieSearch.replace(/\s+/g, '.*\\b').trim());
      const nomeFilmeSemAcento = removeAccents(movies.nome.toLowerCase());
      const regex = new RegExp(`\\b${termoPesquisaSemAcento}.*`, 'i');
      return regex.test(nomeFilmeSemAcento);
    }) : [];

    setMovies(check);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      setmessage('Enviando...');
      count++;
      console.log(count);
      try {
        const response = await fetch(process.env.REACT_APP_API_MOVIE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        },

        );
        const json = await response.json();

        setmessage(json.msg)
        setIsSubmitting(false);

      } catch (error) {
        setmessage("Erro ao adicionar o filme")
      }
    }
  };

  const handleReset = () => {
    setmessage(false);
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    this.setFormData({
      itemvalues: [{}]
    });
  };

  const handleExit = () => {
    localStorage.removeItem("Check");
    navigate("/");
  }

  return (
    <div>
      <div className='logout'>
        <p> <a href="/painel">Adicionar Filmes</a> </p>
        <p> <a href="/painelTv">Adicionar Séries</a> </p>
        <p> <a href="/painelCanal">Adicionar Canal</a> </p>
        <p> <a href="/list">Editar Filmes</a> </p>
        <p> <a href="/listTV">Editar Séries</a> </p>
        <p> <a href="/search">Buscar Filmes</a> </p>
        <button onClick={handleExit}>Sair</button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='container_input'>
          <input
            type="text"
            name="nome"
            placeholder='Titulo'
            onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
            autocomplete="off"
          />
          {movies && movies != '' ? <p className='message'>Filme Já Adicionado</p> : <span></span>}

          <input
            type="text"
            name="data"
            placeholder='Lançamento'
            onChange={(e) => setFormData({ ...formData, data: e.target.value })}
            autocomplete="off"
          />
          <input
            type="text"
            name="categoria"
            placeholder='Categoria'
            onChange={(e) => setFormData({ ...formData, categoria: e.target.value })}
            autocomplete="off"

          />
          <input
            type="text"
            name="categoriaEsp"
            placeholder='Categoria Especial'
            onChange={(e) => setFormData({ ...formData, categoriaEsp: e.target.value })}
            autocomplete="off"
          />
          <input
            type="text"
            name="tempo"
            placeholder='Tempo'
            onChange={(e) => setFormData({ ...formData, tempo: e.target.value })}
            autocomplete="off"

          />
          <input
            type="text"
            name="approval"
            placeholder='Aprovação'
            onChange={(e) => setFormData({ ...formData, approval: e.target.value })}
            autocomplete="off"

          />
          <input
            type="text"
            name="sinopse"
            placeholder='Sinopse'
            onChange={(e) => setFormData({ ...formData, sinopse: e.target.value })}
            autocomplete="off"

          />
          <input
            type="text"
            name="img"
            placeholder='IMG'
            onChange={(e) => setFormData({ ...formData, img: e.target.value })}
            autocomplete="off"

          />
          <input
            type="text"
            name="link"
            placeholder='Link'
            onChange={(e) => setFormData({ ...formData, link: e.target.value })}
            autocomplete="off"

          />
          <div className='bt'>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Aguarde...' : 'Enviar'}
            </button>
            <button onClick={() => handleReset()}>Reset</button>
            {message ? <p className='message'>{message}</p> : <p className='messageErr'>{message}</p>}
          </div>
        </div>
      </form>
    </div>
  )
}

export default PainelMovie